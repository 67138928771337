<template>
  <!--1.首先，弹窗页面中要有el-dialog组件即弹窗组件，我们把弹窗中的内容放在el-dialog组件中-->
  <!--2.设置:visible.sync属性，动态绑定一个布尔值，通过这个属性来控制弹窗是否弹出-->
  <el-dialog :visible.sync="detailVisible" width="20%">
    <div class="container">
      <div class="container-img">

        <div class="container-enrol">
          <img src="@/assets/img/bg1.png" alt="" class="cont-img" />
        </div>

        <p class="cont-enrol">报名成功!</p>
      </div>

        <!-- <p class="contact-enrol">请等待相关工作人员联系</p> -->

      <!-- <div class="contact-enrol">
        <span class="contact">请等待相关工作人员联系</span>
      </div> -->

    </div>
  </el-dialog>
</template>
    
    <script>
export default {
  name: "dialogComponent",
  data() {
    return {
      detailVisible: false,
    };
  },
  methods: {
    //3.定义一个init函数，通过设置detailVisible值为true来让弹窗弹出，这个函数会在父组件的方法中被调用
    init(data) {
      this.detailVisible = true;
      //data是父组件弹窗传递过来的值，我们可以打印看看
      console.log(data);
    },
  },
};
</script>
<style lang="scss"  scoped>
.container {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .container-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 317px;
    height: 264px;
    background-image: url("~@/assets/img/bg.png");
    background-repeat: no-repeat;
    .container-enrol  {
    padding-top: 30px;
    .cont-img {
      text-align: center;
      
    }
}
    .cont-enrol {
      color: #029aff;
      font-size: 20px;
    }
  }
  .contact-enrol {
    // display: flex
      color: #999;
      font-size: 14px;
  }
}
</style>
    