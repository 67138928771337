<!--
 * @Author: chenlin 891635769@qq.com
 * @Date: 2022-08-04 18:26:03
 * @LastEditors: chenlin 891635769@qq.com
 * @LastEditTime: 2022-08-10 16:49:40
 * @FilePath: \_web_portal\src\views\onlineLearning\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="main pageMain">
    <Title></Title>
    <div class="topBgc">
      <div class="head-box"></div>
      <div class="content c">
        <div class="top-content">
          <div class="breadcrumb-box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>在线学习</el-breadcrumb-item>
              <el-breadcrumb-item>{{ course.title }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="content-head-box">
            <div>{{ course.title }}</div>
          </div>
          <div class="title-head">
            <el-row>
              <el-col :span="8" style="">
                <div>
                  <img class="img-top" :src="course.litimg" alt="" />
                  <div class="top-sign-up">{{ course.applyNum }}个人已报名</div>
                </div>
              </el-col>
              <el-col :span="14">
                <div class="top-explain">{{ course.subTitle ? course.subTitle : 0}}</div>
              </el-col>

              <el-col :span="6">
                <div class="price">
                  <div>
                    <div class="top-price">
                      原价：<span style="text-decoration: line-through">{{
                        course.originalPrice
                      }}</span>
                    </div>
                  </div>
                  <div class="top-price-button-span">
                    <div>
                      <span>￥</span>
                      <span>{{
                        Math.round(
                          (course.originalPrice -
                            (course.originalPrice * course.discountRate) /
                              100) *
                            100
                        ) / 100
                      }}</span>
                    </div>
                  </div>
                </div>
              </el-col>

              <el-col :span="10">
                <div class="top-price-button">
                  <div v-if="course.applyStatus == '1'">
                    <div v-if="!pay">
                      <el-button
                        class="button-apply"
                        @click="signUpImmediately"
                        type="danger"
                        >立即购买</el-button
                      >
                    </div>
                    <div v-else>
                      <el-button class="button-apply-audition" type="danger"
                        >报名成功</el-button
                      >
                    </div>
                  </div>
                  <div v-else-if="course.applyStatus == '0'">
                    <div>
                      <el-button class="button-apply" type="warning"
                        >未开始</el-button
                      >
                    </div>
                  </div>
                  <div v-else-if="course.applyStatus == '2'">
                    <div>
                      <el-button class="button-apply" type="info"
                        >已结束</el-button
                      >
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-if="course.beginTime" class="course-time">
            培训时间: {{moment(course.beginTime).format('YYYY-MM-DD')}} 一 {{ moment(course.endTime).format('YYYY-MM-DD') }}
          </div>
        </div>
      </div>
    </div>
    <div class="row-cont">
      <div class="row-cont-box">
        <el-tabs v-model="activeKey" @tab-click="handleClick">
          <el-tab-pane label="课程简介" name="1">
            <div class="tabs_box" style="min-height: 600px;" v-html="course.intro">
            </div>
            <!-- <img class="img-introduction" v-for="(item, index) in imgIntroduction" :key="index" :src="item" /> -->
          </el-tab-pane>
          <el-tab-pane label="课程大纲" name="2">
            <div style="min-height: 600px;" v-html="course.detail"></div>
            <!--
            <div class="title-show">课程章节</div>
              <div class="bottom-explain-cent" v-for="(item, index) in dataList" :key="index">
                <div class="bottom-explain-cent-fist" @click="openChapters(item, index)">
                  <span>{{ index + 1 }}</span>
                  <span>{{ item.title }}</span>
                  <img v-if="index == openChapter" src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/9e0ff7f8-42a7-4b01-82d2-abaf875279af.png" alt="" />
                  <img v-else src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/22fd2997-d52f-47d5-8636-50567f67e637.png" alt="" />
                </div>
                <el-divider></el-divider>
                <ul v-if="index == openChapter">
                  <li v-for="(items, indexs) in item.data" :key="indexs">{{ items.title }}</li>
                </ul>
              </div>
 -->
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <pay :dialogVisible="dialogVisible" :payInfo="payInfo"></pay>
    <Enroll
      v-if="outerVisible"
      ref="dialog"
      :visible.sync="outerVisible"
    ></Enroll>
    <Footer style="margin-top: 100px"></Footer>
  </div>
</template>

<script>
import { Loading } from 'element-ui'
import Title from '@/components/hometitle/hometitle.vue'
import Footer from '@/components/foot/foot.vue'
import Enroll from '@/components/enroll/enroll.vue'
import pay from '@/components/pay/pay'
import { jobOrgDetail } from '@/api/index/index'
import { rotationChart } from '@/api/home/home'
import {
  jcTrainingDetail,
  trainingApplyPage,
  jcTrainingApplyOrgAdd,
  jcTrainingApplyAdd,
  jcTrainingIsApply,
} from '@/api/training/training'
import { getLoginUser } from '@/api/login/login'
import moment from 'moment'
export default {
  name: '',
  data() {
    return {
      rotationChartList: [], // 轮播图
      activeKey: '1',
      imgList: [
        '../../img/online2.jpg',
        '../../img/online3.jpg',
        '../../img/online4.jpg',
        '../../img/online5.jpg',
      ],
      imgIntroduction: [
        'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/749968ba-54fa-4638-88af-691a49b919ce.png',
        'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/3678f05f-237d-466b-8d0d-928a7d1b18ed.png',
        'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/20aa1c8e-8aff-4288-821e-9c6595e84dd8.png',
        'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/0231de20-d79a-431b-a0cf-bd3e59af2579.png',
      ],

      openChapter: 0,
      outerVisible: false,
      dataList: [],
      a: '',
      b: '',
      course: {},
      jobOrg: {},
      query: this.$route.query,
      params: {
        jobTrainingId: '',
        jobUserId: '',
      },
      pay: false, //是否报名
      dialogVisible: false,
      payInfo: {},
    }
  },
  watch: {},
  props: {},
  components: {
    Title,
    Footer,
    Enroll,
    pay,
  },
  computed: {
    price() {
      console.log(this.a, this.b, this.b / 100, 1 - this.b / 100)
      return this.a && this.b
        ? Math.round((this.a - (this.a * this.b) / 100) * 100) / 100
        : ''
    },
  },

  created() {
    this.params.jobTrainingId = this.query.id
    jcTrainingDetail({ id: this.query.id }).then((res) => {
      console.log(res)
      if (res.success) {
        this.course = res.data
        let userId = localStorage.getItem('userId')
        if (userId) {
          this.params.jobUserId = userId
          this._jcTrainingIsApply()
        }
      }
    })
  },
  mounted() {},
  methods: {
    moment,
    // 查询是否报名
    _jcTrainingIsApply() {
      let obj = {
        jobUserId: this.params.jobUserId,
        id: this.course.id,
      }
      trainingApplyPage(obj).then((res) => {
        console.log(res, res.data.rows.length > 0)
        if (res.data.rows.length > 0) {
          this.pay = true
        } else {
          this.pay = false
        }
      })
    },
    openChapters(item, index) {
      if (this.openChapter == index) {
        this.openChapter = -1
      } else {
        this.openChapter = index
      }
    },
    handleClick(tab, event) {
      this.dialogVisible = false
    },

    enrollClick(data) {
      this.outerVisible = true
      this.$nextTick(() => {
        //这里的dialog与上面组件里面的ref属性值是一致的
        //init调用的是dialog-component组件里面的init方法
        //data是传递给弹窗页面的值
        this.$refs.dialog.init(data)
      })
    },

    signUpImmediately() {
      if (!localStorage.getItem('userId')) {
         this.$confirm('请先登录!', '', {
          confirmButtonText: '登录',
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
         this.$router.push('/login')
        }).catch(() => {});
        return
      }
      getLoginUser().then((res) => {
        if (res.success) {
          localStorage.setItem('userId', res.data.id)
          var userId = res.data.id
          let num = Math.round((this.course.originalPrice -(this.course.originalPrice * this.course.discountRate) /100) *100) / 100
          if(+num){
              this.isApply(userId)
          }else{
            jcTrainingApplyAdd({ jobUserId: userId, jobTrainingId: this.course.id,outTradeNo: -1}).then(res => {
                  console.log(res, '报名')
                  if (res.success) {
                    this.$router.go(0)
              }
            })
          }
        }
      })
    },
    isApply(userId) {
      //开启加载loading....
      var loading = Loading.service({
        lock: true,
        text: '努力加载中....',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      trainingApplyPage({ jobUserId: userId, id: this.course.id }).then(
        (res) => {
          console.log(res, '获取报名', this.course)
          if (res.success) {
            if (res.data.rows.length === 0) {
              this.dialogVisible = true
              // 可以报名
              this.payInfo = {
                amount:
                  Math.round(
                    (this.course.originalPrice -
                      (this.course.originalPrice * this.course.discountRate) /
                        100) *
                      100
                  ) / 100,
                jobUserId: userId,
                num: 1,
                payMethod: '2', //  1 wx ,2 ali
                orderType: '4', // 1 会员 ,2 权益 3 课程 4 培训(模拟的,调接口时还是3(课程))
                userType: '1', // 1 个人 ,2 企业
                productName: this.course.title,
                jobTrainingId: this.course.id,
              }
            } else {
              this.pay = true
            }
            loading.close()
          }
        }
      )
    },
    showApply(userId) {
      //开启加载loading....
      var loading = Loading.service({
        lock: true,
        text: '努力加载中....',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.params.jobUserId = userId
      trainingApplyPage({ jobUserId: userId, id: this.course.id }).then(
        (res) => {
          if (res.data.rows.length > 0) {
            this.pay = true
            this.$message.success('报名成功')
            this.dialogVisible = false
            this.enrollClick()
            loading.close()
          } else {
            this.pay = false
            this.$confirm('支付失败,可前往订单页继续支付', '', {
                confirmButtonText: '继续支付',
                showCancelButton: false,
                type: 'error'
              }).then(() => {
                this.$router.push('/personalCenter/myOrder')
                }).catch(() => {});
            loading.close()
          }
        }
      )
    },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.c {
  width: 1320px;
  margin: 0 auto;
}

p {
  margin: 0;
  padding: 0;
}

.pageMain {
  background-color: rgba(235, 236, 237, 1);
}
.topBgc {
  // background: #11256a;
  position: relative;
  margin-bottom: 230px;
  // border: 1px solid red;

  margin-top: 80px;

  .head-box {
    height: 300px;
    // width: 1920px;
    background: url('~@/assets/onlineLearning/heard3.png');
    // background: url(https://img.js.design/assets/img/62b514bbdd87d249ffb339f7.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.content {
  position: absolute;
  top: 63px;
  left: 50%;
  transform: translateX(-50%);
}

::v-deep .el-breadcrumb__item {
  color: rgba(153, 153, 153, 1) !important;
}
.top-content {
  top: 65px;
  width: 1200px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: white;
  height: 445px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);

  .breadcrumb-box {
    // padding: 17px 30px;
    padding-top: 17px;
    padding-left: 30px;
  }
  .title-head {
    margin-top: 40px;
    // background-color: red;
    // width: 544px;
    height: 284px;
  }
  .content-head-box {
    height: 47px;
    div:nth-child(1) {
      text-align: center;
      padding-top: 50px;
      padding-bottom: 20px;
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 0px;
      color: rgba(51, 51, 51, 1);
    }
  }
}
.img-top {
  width: 300px;
  height: 222.7px;
  margin-left: 34px;
  // float: right;
}
.top-explain {
  margin-left: 30px;
  margin-top: 13px;
  // width: 484px;
  // height: 103px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-align: left;
  vertical-align: top;

  -webkit-line-clamp: 6;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 200px;
}
.top-sign-up {
  margin-left: 30px;
  margin-top: 47px;
  width: 209px;
  height: 21px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 0px;
  color: rgba(153, 153, 153, 1);
  text-align: center;
  vertical-align: top;
}

.price {
  display: flex;
  .top-price {
    height: 20px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 30px;
    text-decoration: line-through;
    color: rgba(153, 153, 153, 1);
    div:nth-child(1) {
      flex: 6;
    }
    div:nth-child(2) {
      text-align: right;
      flex: 6;
    }

    .iconSize {
      font-size: 20px;
    }
    .spanPostion {
      margin-left: 5px;
      position: relative;
      top: -3px;
    }
  }

  .top-price-button-span {
    height: 59px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    .present-price {
      padding-right: 20px;
    }
    div:nth-child(1) {
      flex: 6;
    }
    div > span:nth-child(1) {
      text-align: left;
      font-size: 24px;
      font-weight: 700;
      color: rgba(250, 73, 73, 1);
    }
    div > span:nth-child(2) {
      text-align: left;
      font-size: 40px;
      font-weight: 700;
      color: rgba(250, 73, 73, 1);
    }
    div:nth-child(2) {
      flex: 6;
    }

    .iconSize {
      width: 16px;
      height: 16px;
    }
  }
}

.button-apply-audition {
  width: 160px;
  height: 40px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(21, 146, 234, 1);
  border: 1px solid rgba(21, 146, 234, 1);
  background-color: #fff;
  letter-spacing: 1px;
  position: relative;
  top: 10px;
  right: -70px;
}
.top-price-button {
  margin-top: 10px;
  margin-right: 58px;

  .button-apply {
    float: right;
    position: relative;
    width: 200px;
    height: 40px;
    font-size: 18px;
    font-weight: 400;
    background: rgba(250, 73, 73, 1);
    top: 10px;
    letter-spacing: 1px;
  }
}

::v-deep .el-breadcrumb__inner {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 0px;
}

.row-cont {
  margin-top: 208px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  background-color: white;

  .row-cont-box {
    padding: 20px 34px;
    height: 100%;
  }
  .tabs_box {
    height: 100%;
    /*     margin-top: 47px; */
  }
  .tabs_box_2 {
    height: 100%;
    min-height: 300px;
    margin-top: 47px;
    text-align: center;
  }
  .img-introduction {
    margin: 0px auto;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    margin-bottom: 60px;
  }
  ::v-deep .el-tabs__nav-scroll {
    padding-top: 20px;
    padding-left: 34px;
  }
  ::v-deep .el-tabs__item {
    font-size: 20px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
  }
  ::v-deep .is-active {
    color: rgba(21, 146, 234, 1);
  }
  ::v-deep .el-tabs__nav {
    padding-bottom: 10px;
  }

  ::v-deep .el-tabs__content {
    // padding: 20px;
  }
}

.bottom-explain-cent {
  // border: 1px solid red;
  width: 945px;
  // height: 356px;
  margin: 0 auto;
  ul {
    // border: 1px solid red;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 150px;
  }
  li {
    padding: 17px 0;
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    text-align: left;
  }
}

.bottom-explain-cent-fist {
  padding-top: 20px;
  span:nth-child(1) {
    display: inline-block;
    margin-left: 87px;
    margin-right: 24px;
    width: 60px;
    height: 36px;
    /** 文本1 */
    font-size: 24px;
    font-weight: 700;
    color: rgba(194, 194, 194, 1);
    text-align: right;
  }

  /*
    span:nth-child(2) {
    display: inline-block;
    margin-right: 15px;
    width: 64px;
    height: 27px;

    font-size: 18px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    text-align: left;
  }

 */
  span:nth-child(2) {
    // margin-right: 15px;
    // border: 1px solid red;
    position: relative;
    top: 7px;
    display: inline-block;
    width: 640px;
    overflow: hidden;
    height: 27px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    text-align: left;
  }

  img {
    width: 14.16px;
    height: 7px;
  }
}

.title-show {
  margin: 0 auto;
  height: 36px;
  width: 200px;
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 22px;
  color: rgba(51, 51, 51, 1);
}
.course-time{
  color: #999999;
}
</style>
